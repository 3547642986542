export const YearSize = ({ wine, className = '' }) => {
  const jahrgang =
    wine.attributes.jahrgang && wine.attributes.jahrgang?.value != '0'
      ? wine.attributes.jahrgang.value
      : null;

  return (
    <div className={className}>
      {jahrgang}
      {wine.defaultVariant?.name && (
        <>
          {jahrgang && <>,&nbsp;</>}
          {wine.defaultVariant?.name}
        </>
      )}
    </div>
  );
};
