import { useEffect } from 'react';
import { t } from '../../generated/i18n';
import { trackProductImpressions } from '../../lib/tracking';
import { extract } from '../../lib/utils';
import PostPreview from '../blog/preview';
import CollectionPreview from '../collection/preview';
import Carousel from '../common/carousel';
import EventPreview from '../event/preview';
import MiscPreview from '../misc/preview';
import PackagePreview from '../package/preview';
import PackageSuggestion from '../package/suggestion';
import ProductPreview from '../product/preview';
import WinePreview from '../wine/preview';

const components = {
  wein: WinePreview,
  event: EventPreview,
  'physischer-event': EventPreview,
  package: PackagePreview,
  allerlei: MiscPreview,
  giftcard: MiscPreview,
  collection: CollectionPreview,
  suggestion: PackageSuggestion,
};

export const nodeSelector = extract('node');

function makeChildElements(Component, items, selector, childProps, listName) {
  const trackingProducts = [];

  if (!items || !items.map) {
    console.error(
      'makeChildElements: Empty or invalid render request',
      items,
      Component,
      childProps,
    );
    return [];
  }

  return [
    trackingProducts,
    items.map((item, index) => {
      if (selector) {
        item = selector(item);
      }

      if (!item) return <div key={index}></div>;

      let Identified = Component;

      // guess type
      if (!Identified) {
        Identified = components[item.type];
        if (!Identified) {
          if (item?._meta) {
            Identified = PostPreview;
          } else {
            // fallback
            Identified = ProductPreview;
          }
        }
      }

      if (Identified.isProduct) {
        trackingProducts.push(item);
      }

      return (
        <Identified
          {...childProps}
          key={index}
          item={item}
          listName={listName}
        />
      );
    }),
  ];
}

const List = ({
  items,
  Wrapper = Carousel,
  childProps = {},
  component = undefined,
  selector = undefined,
  listName = undefined,
  ...props
}) => {
  const [trackingProducts, children] = makeChildElements(
    component,
    items,
    selector,
    childProps,
    listName,
  );

  if (!items || items.length === 0) {
    // TODO: translate
    return <div className='ml-6'>{t('Keine Resultate gefunden')}</div>;
  }

  useEffect(() => {
    if (trackingProducts && trackingProducts.length > 0) {
      trackProductImpressions(trackingProducts, listName);
    }
  }, [listName]);

  return <Wrapper {...props}>{children}</Wrapper>;
};

export default List;
