import ProductPreview from '../product/preview';

const PackagePreview = ({ item, ...props }) => {
  return (
    <ProductPreview
      item={item}
      imageClassName='rounded-lg-img rounded-lg w-full h-[215px]'
      imageStyle={
        item.type === 'package'
          ? {}
          : { background: item.metadata?.['bg-color'] }
      }
      allowMultipleAdd={false}
      {...props}
    >
      <p className='mt-3 flex-grow border-t border-0.3 pt-4'>
        {item.metadata.lead}
      </p>
    </ProductPreview>
  );
};

PackagePreview.isProduct = true;

export default PackagePreview;
