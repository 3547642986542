import Link from 'next/link';
import { t } from '../../generated/i18n';

const Attribute = ({ img, title, values }) => (
  <tr className=''>
    <td className='w-10 py-2 align-top' align='center'>
      <img alt={title} src={img} className='h-22px' />
    </td>
    <td className='py-2'>
      <p className='inline'>{values}</p>
    </td>
  </tr>
);

const getLinAttributes = (attributes, key) =>
  attributes[key].value.split(', ').map((s, index) => (
    <Link
      href={`/wein?filter=${key}--${s}`}
      key={index}
      className='transition duration-200 hover:text-green'
    >
      {index > 0 && ', '}
      {s}
    </Link>
  ));

export const Attributes = ({ wine, className = '' }) => {
  const attributes = wine.attributes;

  return (
    <table className={`font-bold ${className}`}>
      <tbody>
        <Attribute
          img='/static/attribute/traube.svg'
          title={t('grape')}
          values={getLinAttributes(attributes, 'rebsorten')}
        />
        <Attribute
          img='/static/attribute/barrels.svg'
          title={t('wineAging')}
          values={attributes.weinausbau.value}
        />
        {attributes.aroma && (
          <Attribute
            img='/static/attribute/taste.svg'
            title={t('taste')}
            values={attributes.aroma.value.split(', ').map((s, index) => (
              <Link
                href={`/wein?q=${s}`}
                key={index}
                className='transition duration-200 hover:text-green'
              >
                {index > 0 && ', '}
                {s}
              </Link>
            ))}
          />
        )}
        <Attribute
          img='/static/attribute/meal.svg'
          title={t('foodPairing')}
          values={getLinAttributes(attributes, 'passt-zu')}
        />
        <Attribute
          img='/static/attribute/temperature.svg'
          title={t('temperature')}
          values={
            wine.category.slug == 'rotwein'
              ? t('rotweinTemperature')
              : t('allTemperature')
          }
        />
        <Attribute
          img='/static/attribute/glas.svg'
          title={t('alcoholicContent')}
          values={
            <Link
              href={`/wein?filter=alkohol-vol--${
                attributes['alkohol-vol'].value - 0.2
              }f-t${attributes['alkohol-vol'].value + 0.2}`}
              className='transition duration-200 hover:text-green'
            >
              {`${attributes['alkohol-vol'].value} % Vol.`}
            </Link>
          }
        />
      </tbody>
    </table>
  );
};

export const getStructuredText = (wine) =>
  wine.attributes['strukturtext-v2']?.value ??
  wine.attributes.strukturtext?.value;

const iconMap = {
  // text
  'rot, leicht': { src: 'rot-leicht.svg' },
  'rot, ausgewogen': { src: 'rot-ausgewogen.svg' },
  'rot, kräftig': { src: 'rot-kraftig.svg' },
  'rot, schwer': { src: 'rot-schwer.svg' },
  'weiss, leicht': { src: 'weiss-leicht.svg' },
  'weiss, ausgewogen': { src: 'weiss-ausgewogen.svg' },
  'weiss, expressiv': { src: 'weiss-expressiv.svg' },
  'weiss, süsslich': { src: 'weiss-susslich.svg' },

  'sparkling, leicht': { src: 'weiss-leicht.svg' },
  'sparkling, ausgewogen': { src: 'weiss-ausgewogen.svg' },
  'sparkling, expressiv': { src: 'weiss-expressiv.svg' },
  'sparkling, schwer': { src: 'rot-schwer.svg' },
  'sparkling, süsslich': { src: 'weiss-susslich.svg' },

  'rosé, leicht': { src: 'rot-leicht.svg' },
  'rosé, ausgewogen': { src: 'rot-ausgewogen.svg' },
  'rosé, kräftig': { src: 'rot-kraftig.svg' },
  'rosé, schwer': { src: 'rot-schwer.svg' },

  // key
  red1: { src: 'rot-leicht.svg' },
  red2: { src: 'rot-ausgewogen.svg' },
  red3: { src: 'rot-kraftig.svg' },
  red4: { src: 'rot-schwer.svg' },
  white1: { src: 'weiss-leicht.svg' },
  white2: { src: 'weiss-ausgewogen.svg' },
  white3: { src: 'weiss-expressiv.svg' },
  white4: { src: 'weiss-susslich.svg' },
};

export const StructureIcon = ({
  structureText,
  structure = undefined,
  inverse = false,
  className = 'w-[20px]',
}) => {
  let structureIcon = iconMap[structureText];
  if (!structureIcon && structure) {
    structureIcon = iconMap[structure];
  }

  if (structureIcon) {
    const src = `/static/structure/${inverse ? 'inverse/' : ''}${
      structureIcon.src
    }`;

    return <img className={className} alt='' src={src} />;
  }

  return <></>;
};
